import * as React from 'react';
import { useState, useEffect } from 'react';
import { AlertVariant, Button, Grid, ProgressBar } from 'react-unity';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../common/modals/AlertModal';
import AWSReduceAccount from '../../models/entities/AWSReduceAccount';
import AWSAPermissionInfo from '../../models/entities/AWSPermissionInfo';
import AWSSSOService from '../../services/AWSSSOService';
import GroupLookup from '../common/form-controls/GroupLookup';
import ReactButton from '../common/ReactButton';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import SelectField from '../common/form-controls/SelectField';
import ToggleField from '../common/form-controls/ToggleField';
import Group from '../../models/Group';

interface Option {
	value: string;
	text: string;
}

const AwsSsoHome = () => {
	const [areMyRequestsLoading, setAreMyRequestsLoading] = useState<boolean>(false);
	const [progressLabel, setProgressLabel] = useState<string>('Loading...');

	const [accountOptions, setAccountOptions] = useState<Option[]>([]);
	const [optionSelec, setOptionSelec] = useState<Option>({ value: null, text: '' });
	const [accountList, setAccountList] = useState<AWSReduceAccount[]>([]);
	const [account, setAccount] = useState<AWSReduceAccount>({ id: '', name: '', arn: '' });

	const [group, setGroup] = useState<Group>();

	const [permissionType, setPermissionType] = useState<string>('0');

	const [permissionsToSelect, setPermissionsToSelect] = useState<AWSAPermissionInfo[]>([]);
	const [permissionsSelected, setPermissionsSelected] = useState<string>('');
	const [permissionLoading, setPermissionLoading] = useState<boolean>(false);

	const [operationInProgress, setOperationInProgress] = useState<boolean>(false);

	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<string>('');
	const [alertVariant, setAlertVariant] = useState<AlertVariant>('default');
	
	const navigate = useNavigate();
	const awsSSOService = new AWSSSOService();

	const endAndRedirect = () => {
		navigate('/');
	};

	const isValid = () => {
		return account && permissionsSelected && group;
	};

	const retriveAwsAccounts = () => {
		setAreMyRequestsLoading(true);
		setProgressLabel('Retrieving AWS Accounts...');
		awsSSOService.getAllAWSByUser().then(response => {
			const accountArray = response.map(element => ({
				id: element.id,
				name: element.name,
				arn: element.arn
			}));
			setAccountList(accountArray);
			let options = [];
			response.map(element => {
				options.push({ value: element.id, text: element.name });
			});
			setAccountOptions(options);
		})
		.catch(error => {
			setAlertMessage(error.message);
			setShowAlert(true);
		})
		.finally(() => {
			setAreMyRequestsLoading(false);
		});
	}

	const handleAccountChange = (event) => {
		setOperationInProgress(true);
		setPermissionLoading(true);
		const opSel = accountOptions[event.target.selectedIndex-1];
		setOptionSelec(opSel);
		const accSel = accountList[event.target.selectedIndex-1]; 
		setAccount(accSel);
		setOperationInProgress(false);
		setPermissions(permissionType, accSel);
	}

	const manageChangeGroup = (event: any) => setGroup(event);

	const manageChangeRequestType = (event) => {
		setPermissionLoading(true);
		if (event.target.value != permissionType) setPermissionType(event.target.value);
		setPermissionsSelected('');
		setPermissionsToSelect([]);
		setPermissions(event.target.value, account);
	}

	const scopePermissionOptions = () => {
		const options = permissionsToSelect.map(element => {
			return { value: element.permissionSetArn, text: element.name };
		}) || [];
		return options;
	};


	const handleOnChangePermission = (e) => {
		try {
			setPermissionsSelected(e.target.value);
		}
		catch(error) {
			setAlertMessage(error.message);
			setShowAlert(true);
		}
	}

	const setPermissions = (permission: string, acc?: AWSReduceAccount) => {
		if (acc.id == '') {
			setPermissionLoading(false);
			return;
		}
		const permissionRequest = permission == '0' ?
			awsSSOService.getListOfPermissions(acc.id) :
			awsSSOService.getAssignedPermissions(acc.id);
		permissionRequest.then(response => {
			setPermissionsToSelect(response);
		})
		.catch(error => {
			setAlertMessage(error.message);
			setShowAlert(true);
		})
		.finally(() => {
			setPermissionLoading(false);
		});
	}

	const handleSubmit = () => {
		setOperationInProgress(true);
		const permission = {
			accountNumber: account.id,
			permissionSetARN: permissionsSelected,
			principalName: group.id,
			principalType: 'GROUP',
			status: 'Created',
		}
		const request = permissionType == '0' ? 
			awsSSOService.submitSetPrincipalsAndPermission(permission) :
			awsSSOService.submitUnsetPrincipalsAndPermission(permission);
		request.then(() => {
			setAlertVariant('success');
			setAlertMessage('Request submitted successfully');
		})
		.catch(error => {
			setAlertVariant('error')
			setAlertMessage(error.message);
		})
		.finally(() => {
			setShowAlert(true);
			setOperationInProgress(false);
		});
	}

	useEffect(() => {
		retriveAwsAccounts();
	}, []);

	return (
		<div>
			<SectionHomeWrapper title={`AWS SSO`}>
				{(areMyRequestsLoading) && (
					<ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label={progressLabel} />
				)}				
			</SectionHomeWrapper>
			{!areMyRequestsLoading && (
				<>
				<RequestFormWrapper
					title={"New AWS SSO Request"}
					loading={areMyRequestsLoading}
				>
					<Grid variant="halves">
						<Grid.Item>
							<SelectField
								label={'Select an AWS Account *'} 
								options={accountOptions}
								className="full-width"
								value={optionSelec.value}
								onChange={(event) => handleAccountChange(event)}
							/>
						</Grid.Item>
					</Grid>
					<Grid variant="halves">
						<Grid.Item>
							<GroupLookup
								value={group?.displayName}
								onChange={(event) => manageChangeGroup(event)}
								label="Azure Entra ID Group *"
								placeholder="Group Name"
								note="This must be an existing group in Microsoft Entra ID"
							/>
						</Grid.Item>
					</Grid>
					<Grid variant="halves">
						<Grid.Item>
							<ToggleField
								label="Request Type *"
								onChange={(event) => manageChangeRequestType(event)}
								value={permissionType}
								options={
									[
										{ value: '0', text: 'Add' },
										{ value: '1', text: 'Remove' },
									]
								}
							/>
						</Grid.Item>
					</Grid>
					<Grid variant="halves">
						<Grid.Item>
							{!permissionLoading ?
								(permissionsToSelect.length > 0 ? 
									<SelectField
										label={`Select permision ${permissionType == '0' ? 'to be added *' : 'to be removed *'}`}
										value={permissionsSelected}
										options={scopePermissionOptions()}
										className="full-width"
										onChange={handleOnChangePermission}
									/>
								:
								<strong>No permissions available</strong>)
							:
								<ProgressBar indeterminate hideValueLabel label={"Retrieving Permissions..."} />
							}
						</Grid.Item>
					</Grid>
					<Grid variant="4-up">
						<Grid.Item>
							<Button
								variant="secondary"
								onClick={() => endAndRedirect()}
								disabled={operationInProgress}
							>
								Cancel Request
							</Button>
						</Grid.Item>
						<Grid.Item />
						<Grid.Item>
							<ReactButton
								variant="primary"
								disabled={!isValid() || operationInProgress}
								isLoading={operationInProgress}
								handleUpdateSubmit={handleSubmit}
								loadingVariant="secondary"
								name="Submit Request"
							/>
						</Grid.Item>
					</Grid>
				</RequestFormWrapper>
			</>
			)}
			<AlertModal
				visible={showAlert}
				willTimeout={false}
				text={alertMessage}
				variant={alertVariant}
				onClose={() => {
					setShowAlert(false);
					setAlertMessage('');
					endAndRedirect();
				}}
			/>
		</div>
	);
}

export default AwsSsoHome;
