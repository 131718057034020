import * as React from 'react';
import {
	Field as UnityField,
	Tag,
	Grid,
	Alert,
	AlertVariant,
	Tooltip,
	Typeahead
} from 'react-unity';
import CartaService from '../../../services/CartaService';
import SelectField from '../../common/form-controls/SelectField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import CartaObjectLookup from '../../../models/viewModels/CartaId/CartaObjectLookup';
import CartaId from '../../../models/entities/CartaId';
import ReadonlyField from '../../common/form-controls/ReadonlyField';
import { useState, useRef } from 'react';
import { Validation } from '../../../utils/Validation';
interface CartaInputProps extends FieldHandler<CartaId[]> {
	id?: string;
}


interface CartaInputState {
	typeSelected: string;
	searchText: string;
	searching: boolean;
	cartaSearchResults: CartaObjectLookup[];
	retrievedResults: boolean;
	issueAlert: {
		variant: AlertVariant;
		message: string;
	};
}

const CartaInput = (props: CartaInputProps, homeState: CartaInputState) => {

	const cartaService = new CartaService();
	const [fieldLabel] = useState<string>('DBM ID');
	const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>(null);
	const [searching, setSearching] = useState<boolean>(false);
	const [issueAlert, setIssueAlert] = useState(null);
	const [cartaSearchResults, setCartaSearchResults] = useState<CartaObjectLookup[]>([]);
	const [retrievedResults, setRetrievedResults] = useState<boolean>(false);
	const searchText = useRef<string>('');
	const typeSelected = useRef<string>('APP');


	const getInitialState = () => {
		searchText.current = '';
		setSearching(false);
		setIssueAlert(null);
		setCartaSearchResults([]);
		setRetrievedResults(false);
	};

	const resetState = () => {
		getInitialState();
	};

	const searchCartaIdInfo = async () => {
		setCartaSearchResults([]);
		const ids = props.value.map((cartaId) => cartaId.cartaAppId);
		const appId = `${typeSelected.current}-${searchText.current}`;
		if (ids.includes(appId)) {
			setIssueAlert({ variant: 'warning', message: `${appId} is already in the list.` })
			return;
		};
		const searchString = searchText.current.trim();
		if (searchString?.length <= 3)
			return;
		setSearching(true);
		try {
			const cartaIdInfo = (await cartaService.searchCartaObjects(searchString, typeSelected.current)).map(cartaInfo => new CartaObjectLookup(cartaInfo));
			console.log(cartaIdInfo)
			setCartaSearchResults(cartaIdInfo);
		} catch (error) {
			const HTTP_NOT_FOUND_ERROR_CODE = 404;
			if (error.status !== HTTP_NOT_FOUND_ERROR_CODE) {
				setIssueAlert({
					variant: 'error',
					message: 'An unexpected error occurred while retrieving data from Carta.'
				});
			}
		}
		setSearching(false);
		setRetrievedResults(true);
	}

	const addSearchedCartaId = (cartaId: CartaObjectLookup) => {
		props.onChange([...props.value,
		{ cartaAppId: cartaId.applicationId, cartaAppName: cartaId.name }
		]);
		resetState();
	}

	const isValid = (): boolean => {
		if (!props.validation) return true;
		return new Validation(props.validation).assert(props.value);
	}

	const nonValidMessage = () => {
		if (isValid()) return null;
		const invalidRule = props.validation.rules?.find((rule) => !rule.assert(props.value));
		if (!invalidRule) return '';

		const message = invalidRule?.message;
		if (typeof message == 'string') return message;
		return message(props.value);
	}

	const showNonValidMessage = (): boolean => {
		return !isValid() && !issueAlert && !retrievedResults;
	}

	const handleOnChangeCartaTypeahead = (): void => {
		if (searchTimeout) clearTimeout(searchTimeout);
		if (typeSelected.current && searchText.current) {
			setSearchTimeout(setTimeout(() => {
				searchCartaIdInfo();
			}, 1000));
		}
	}

	const checkIfDMBAlreadySelected = (value) => {
		const cartaId = cartaSearchResults.find((c) => c.applicationId === value);
		const ids = props.value.map((c) => c.cartaAppId);
		if (ids.includes(value)) {
			setIssueAlert({
				variant: 'warning', message: `${value} is already in the list.`
			});

		} else {
			addSearchedCartaId(cartaId);
		}
	}

	const handleEventSearch = (event) => {
		typeSelected.current = event.target.value;
		if (searchText.current) searchCartaIdInfo();
		const cartaIds = props.value.filter(t => t.cartaAppId !== '');
		props.onChange(cartaIds);
	}

	const handleSearchUpdate = (e) => {
		searchText.current = e.target.value;
		setCartaSearchResults([]);
		setRetrievedResults(false);
		handleOnChangeCartaTypeahead();
	}


	const cartaTagGroup = () => (
		<Tag.Group>
			{props.value.filter(t => t.cartaAppId !== '').map((cartaId: CartaId) => (
				<Tooltip className="margin" variant="below" key={`tooltip-${cartaId.cartaAppId}`}>
					<Tooltip.Passage>
						<Tag
							key={`tag-${cartaId.cartaAppId}`}
							onCloseAction={
								!props.disabled ?
									() => {
										const cartaIds = props.value.filter(c => c.cartaAppId !== cartaId.cartaAppId);
										props.onChange(cartaIds);
									}
									: undefined
							}
						>
							{`${cartaId.cartaAppId}`}
						</Tag>
					</Tooltip.Passage>
					<Tooltip.Content>
						{cartaId.cartaAppName}
					</Tooltip.Content>
				</Tooltip>
			))}
		</Tag.Group>
	);

	const disabledView = () => {
		if (props.value.length === 0) {
			return (
				<Grid variant="halves">
					<Grid.Item>
						<ReadonlyField
							label={fieldLabel}
							text=''
						/>
					</Grid.Item>
				</Grid>
			);
		}

		return (
			<UnityField>
				<UnityField.Label>{fieldLabel}</UnityField.Label>
				<UnityField.Body>
					{cartaTagGroup()}
				</UnityField.Body>
			</UnityField>
		);
	};

	return (
		<> {props.disabled && disabledView()}
			{!props.disabled &&
				<UnityField error={!isValid()} id={props.id}>
					<UnityField.Label>
						{fieldLabel}
					</UnityField.Label>
					<Grid variant="halves">
						<Grid.Item>
							<UnityField.Body className="em-c-search__body">
								<SelectField
									className="em-u-margin-none"
									value={typeSelected.current}
									options={['APP']}
									onChange={(event) => handleEventSearch(event)}
									disabled
								/>
								<Typeahead
									className="em-u-width-100"
									onChange={(e, value) => checkIfDMBAlreadySelected(value)}
									fieldProps={{
										label: '',
										error: !isValid(),
										note: '',
									}}
									inputProps={{
										onChange: (e) => handleSearchUpdate(e),
										placeholder: 'ID or Name',
										value: searchText.current,
										loading: searching,
									}}
								>
									{retrievedResults && !cartaSearchResults?.length ? (
										<Typeahead.Item value={0} onClick={() => { }}>
											<Typeahead.Item.Suggestion aria-disabled={true}>
												<i>Object was not found</i>
											</Typeahead.Item.Suggestion>
										</Typeahead.Item>
									) : (
										cartaSearchResults?.map((cartaId) => (
											<Typeahead.Item value={cartaId.applicationId} key={cartaId.applicationId}>
												<Typeahead.Item.Suggestion>
													<span><strong>{`${cartaId.applicationId}`}</strong>{` (${cartaId.name})`}</span>
												</Typeahead.Item.Suggestion>
											</Typeahead.Item>
										))
									)}
								</Typeahead>
							</UnityField.Body>
							{showNonValidMessage() &&
								<UnityField.Note>
									<p>{nonValidMessage()}</p>
								</UnityField.Note>}
							{issueAlert && (
								<Alert
									className="em-u-width-100"
									variant={issueAlert.variant}
									onClose={() => setIssueAlert({ variant: null, message: null })}
								>
									{issueAlert.message}
								</Alert>
							)}
						</Grid.Item>
						<Grid.Item>
							{cartaTagGroup()}
						</Grid.Item>
					</Grid>
				</UnityField>
			}	
		</>
	);
}

export default CartaInput;
