import AWSAccount from '../models/entities/AWSAccount';
import AWSReduceAccount from '../models/entities/AWSReduceAccount';
import AWSPermission from '../models/entities/AWSPermissionRequest';
import AWSPermissionInfo from '../models/entities/AWSPermissionInfo';
import ApiClient from './ApiClient';

export default class AWSSSOService extends ApiClient {
	async getAWSAccountDetails(accountId: string) {
		return this.get<AWSReduceAccount>(`AWSSingleSignOn/GetAccountDetails/${accountId}`);
	}

	async getAllAWSByUser() {
		return this.get<AWSReduceAccount[]>(`AWSSingleSignOn/GetAccountsForOwner`);
	}

	async getAssignedPermissions(accountId: string) {
		return this.get<AWSPermissionInfo[]>(`AWSSingleSignOn/GetAssignedPermissionSetPrincipalData/${accountId}`);
	}

	async getListOfPermissions(accountId: string) {
		return this.get<AWSPermissionInfo[]>(`AWSSingleSignOn/ListPermissionSets/${accountId}`);
	}

	async submitSetPrincipalsAndPermission(model: AWSPermission) {
		return this.post('AWSSingleSignOn/SetPrincipalsAndPermission', model);
	}
	
	async submitUnsetPrincipalsAndPermission(model: AWSPermission) {
		return this.post('AWSSingleSignOn/UnSetPrincipalsAndPermission', model);
	}
}